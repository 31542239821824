/* eslint-disable camelcase */
/* eslint-disable react/prop-types,max-len */
import React, { useState, useEffect } from 'react';

import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import Checkbox from 'antd/es/checkbox';
import Radio from 'antd/es/radio';
import { get, find, has } from 'lodash-es';
import { connect } from 'react-redux';
import Spin from 'antd/es/spin';
import { loadOrders as loadOrdersAction } from '../../redux/ActionsAndReducers/Orders';
import {
  loadCustomers,
  loadOneCustomer,
} from '../../redux/ActionsAndReducers/Customers';
import Api from '../../api/Methods';
import { showSiderAction, hideSiderAction } from '../../redux/sider/actions';
import './CustomerFormStyles.css';
import CustomerLocationField from './CustomerLocationField';
import CustomerLeveringAddress from './CustomerLeveringAddress';

const handleSubmission = (
  form,
  e,
  setLoading,
  customerFullDatasBeforeUpdate,
  _loadCustomers,
  hideSider,
  loadOrders,
  afterCreate
) => {
  e.preventDefault();
  form.validateFields(async (errors, values) => {
    if (errors) {
      return false;
    }
    setLoading(true);

    const customerId = customerFullDatasBeforeUpdate
      ? customerFullDatasBeforeUpdate.id || customerFullDatasBeforeUpdate._id
      : null;

    let { mvaPercent } = values;
    mvaPercent = parseFloat(values.mvaPercent || "25");
    // eslint-disable-next-line no-restricted-globals
    mvaPercent = !isNaN(mvaPercent) ? mvaPercent : 25;
    // eslint-disable-next-line operator-assignment
    mvaPercent =  mvaPercent / 100;

    const val = {
      ...values,
      mvaPercent,
      addressOnMap: {
        formatted: values.addressOnMap,
        value: values.addressOnMap,
        lat: values.lat,
        lng: values.lng,
      },
    };

    if (!customerId) {
      const { status, data } = await Api.createCustomer(val);
      if(status === 200) {
        await Api.syncCustomerWc(data.id);
      }
    } else {
      await Api.updateCustomer(customerId, val);
      await Api.syncCustomerWc(customerId);
    }

    setLoading(false);
    _loadCustomers();
    hideSider();
    // reload orders if on the dashboard page
    if (window.location.href.includes('/dashboard/main')) loadOrders();
    if (!customerId && typeof afterCreate === 'function') {
      afterCreate();
    }

    return true;
  });
};

const resolveCustomer = (idOrData) => (customerStore) => (findOneCustomer) => (
  isLoading
) => {
  const inputIsId = typeof idOrData === 'string';
  const inputIsEmptyArray = Array.isArray(idOrData) && idOrData.length === 0;
  const inputIsCustomerObject =
    typeof idOrData === 'object' && has(idOrData, '_id');
  const resolveFromStore = () =>
    find(customerStore, (c) => c.id === idOrData || c._id === idOrData);
  if (inputIsCustomerObject) return idOrData;
  if (inputIsEmptyArray) return null;
  if (inputIsId && !isLoading)
    return resolveFromStore() || findOneCustomer(idOrData);

  return null;
};

const CustomerForm = ({
  datas,
  form,
  _loadCustomers,
  viewLoading,
  rdxLoadOne,
  customers,
  showSider,
  hideSider,
  loadOrders,
  afterCreate,
}) => {
  const [loading, setLoading] = useState(false);
  const [fieldHasChanged, setFieldHasChanged] = useState(false);
  const [sendInvoiceState, setStateSendInvoice] = useState("print");
  const customer = resolveCustomer(datas)(customers)(rdxLoadOne)(viewLoading);

  useEffect(() => {
    form.resetFields();
    setStateSendInvoice(get(customer, 'values.send_invoice', "print"));
  }, [customer]);

  const formChanged = form.isFieldsTouched();
  
  useEffect(() => {
    if(form.isFieldsTouched()) {
      setFieldHasChanged(true);
    }
  }, [formChanged]);

  return (
    <Spin spinning={viewLoading && !customer}>
      <Form
        layout="inline"
        name="customer-form"
        className="customer-form"
        onSubmit={(e) => {
          handleSubmission(
            form,
            e,
            setLoading,
            customer,
            _loadCustomers,
            hideSider,
            loadOrders,
            afterCreate
          );
        }}
      >
        <Row gutter={16}>
          <Col xs={24} style={{ textAlign: 'center' }}>
            <Icon
              type="user"
              style={{
                background: 'grey',
                color: 'white',
                padding: '1rem',
                borderRadius: '1rem',
              }}
            />
            <h3 style={{ marginBottom: '2rem' }}>
              {get(customer, 'values.name', 'New Customer')}
            </h3>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="KundeId" label="Kunde ID">
              {form.getFieldDecorator('kundenummer', {
                rules: [{ required: true, message: 'Kunde ID is required' }],
                initialValue: get(customer, 'values.kundenummer', ''),
              })(
                <Input prefix={<Icon type="number" />} placeholder="Kunde ID" />
              )}
            </Form.Item>
          </Col>
          
          <Col xs={24} md={12}>
            <Form.Item
              name="Navn"
              label="Navn"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              {form.getFieldDecorator('name', {
                rules: [{ required: true, message: 'Name is required' }],
                initialValue: get(customer, 'values.name', ''),
              })(<Input prefix={<Icon type="user" />} placeholder="Navn" />)}
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item name="Organisasjonsnummer" label="Organisasjonsnummer">
              {form.getFieldDecorator('company_number', {
                // rules: [{ required: true, message: 'organisasjonsnummer is required' }],
                initialValue: get(customer, 'values.company_number', ''),
              })(<Input prefix={<Icon type="bank" />} placeholder="Organisasjonsnummer" />)}
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item name="Epost" label="Epost">
              {form.getFieldDecorator('email', {
                rules: [
                  { required: true, message: 'Email is required' },
                  { type: 'email', message: 'Email is not correct' },
                ],
                initialValue: get(customer, 'values.email', ''),
              })(<Input prefix={<Icon type="mail" />} placeholder="Epost" />)}
            </Form.Item>
          </Col>
          
          <Col xs={24} md={12}>
            <Form.Item name="Adresse" label="Adresse">
              {form.getFieldDecorator('adresse', {
                initialValue: get(customer, 'values.adresse', ''),
              })(<Input prefix={<Icon type="home" />} placeholder="Adresse" />)}
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item name="Postnummer" label="Postnummer">
              {form.getFieldDecorator('postnummer', {
                initialValue: get(customer, 'values.postnummer', ''),
              })(
                <Input
                  prefix={<Icon type="number" />}
                  placeholder="Postnummer"
                />
              )}
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item name="Poststed" label="Poststed">
              {form.getFieldDecorator('poststed', {
                initialValue: get(customer, 'values.poststed', ''),
              })(
                <Input prefix={<Icon type="global" />} placeholder="Poststed" />
              )}
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item name="Telefonnummer" label="Telefonnummer">
              {form.getFieldDecorator('phone', {
                initialValue: get(customer, 'values.phone', ''),
              })(
                <Input
                  prefix={<Icon type="phone" />}
                  placeholder="Telefonnummer"
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <CustomerLocationField form={form} customer={customer} setFieldHasChanged={setFieldHasChanged} />
          </Col>
          <Col xs={24} md={12}>
            <CustomerLeveringAddress form={form} customer={customer} setFieldHasChanged={setFieldHasChanged} />
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="mvaPercent" label="MVA %">
                {form.getFieldDecorator('mvaPercent', {
                  initialValue: get(customer, 'values.mvaPercent', 0.25) * 100,
                })(
                  <Input
                    type="number"
                    // prefix={<Icon type="phone" />}
                    placeholder="25%"
                  />
                )}
              </Form.Item>
          </Col>
          
        </Row>

        <Row>
          <Col xs={24} md={12}>
            <Form.Item name="send_invoice" label="Choose the way how to receive invoice">
              {form.getFieldDecorator('send_invoice', {
                initialValue: get(customer, 'values.send_invoice', ''),
              })(
                <Radio.Group onChange={(v) => setStateSendInvoice(v.target.value)}>
                <Radio value="print">
                  Print
                </Radio>
                <Radio  value="email">
                  Email
                </Radio>
              </Radio.Group>
              )}
            </Form.Item>
            {sendInvoiceState === "email" &&
            <Form.Item name="send_invoice_email">
              {form.getFieldDecorator('send_invoice_email', {
                initialValue: get(customer, 'values.send_invoice_email', ''),
              })(
                <Input prefix={<Icon type="mail" />} placeholder="Receipient Email" />
              )}
            </Form.Item>}
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="archived" label="Archived">
                {form.getFieldDecorator('archived', {
                  initialValue: get(customer, 'values.archived', false),
                })(
                  <Checkbox>Archived</Checkbox>
                )}
            </Form.Item>
          </Col>
        </Row>

        {/* get(customer, 'values.email', null) && (
          <Row>
            <Col xs={24}>
              <Descriptions
                title="Extra infos"
                bordered
                layout="vertical"
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
              >
                <Descriptions.Item label="Antall Ordre">
                  {get(customer, 'values.ordersNumber', '')}
                </Descriptions.Item>
                <Descriptions.Item label="Niva">
                  {get(customer, 'values.ordersLevel', '')}
                </Descriptions.Item>
                <Descriptions.Item label="Totalkjøp">
                  {get(customer, 'values.ordersAmount', '')}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        ) */}

        <Row style={{ marginTop: '1rem' }}>
          <Col xs={24}>
            {get(customer, 'values.orders', []).map((order) => (
              <Button
                key={order.id || order._id}
                type="primary"
                shape="round"
                icon="profile"
                size="small"
                style={{ margin: '0 0.2rem' }}
                onClick={() => {
                  showSider('order', get(order, '_id', get(order, 'id', {})));
                }}
              >
                {get(order, 'values.number', '-')}
              </Button>
            ))}
          </Col>
        </Row>
       

        <Row style={{ marginTop: '2rem', textAlign: 'right' }}>
          <Col xs={24}>
            <Button
              type="primary"
              icon="save"
              size="large"
              // disabled={!form.isFieldsTouched()}
              disabled={!fieldHasChanged}
              htmlType="submit"
              loading={loading}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

const mapStateToProps = ({ Ui, Customers }) => ({
  siderShown: Ui.sider.show,
  viewLoading: Customers.isLoading,
  customers: Customers.customers,
});

const mapDispatchToProps = (dispatch) => ({
  _loadCustomers: () => dispatch(loadCustomers()),
  rdxLoadOne: (email) => dispatch(loadOneCustomer(email)),
  showSider: (...args) => dispatch(showSiderAction(...args)),
  hideSider: (...args) => dispatch(hideSiderAction(...args)),
  loadOrders: (...args) => dispatch(loadOrdersAction(...args)),
});

export default Form.create({ name: 'Customer_form' })(
  connect(mapStateToProps, mapDispatchToProps)(CustomerForm)
);
